import {createSlice} from '@reduxjs/toolkit'
import {invoiceFormFieldSet, logError} from '../../utils/DefaultFunctions'
import {STRINGS} from '../../utils/Enums'
import {API} from '../../utils/services'

export const uploadFileRequest =
  (formData, selectedId, controller, stateVar, openNotification) => (dispatch) => {
    dispatch(setCokeFileUploadSuccess(false))
    dispatch(requestPending(true))
    // dispatch(
    //   uploadFileSuccess({
    //     data: {
    //       data: {
    //         fileUploadPending: true,
    //       },
    //     },
    //     selectedId,
    //   })
    // )

    API.post('api/FileUpload/ProcessFilesUpload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal: controller?.signal,
    })
      .then((res) => {
        //here we will set data in redux state
        console.log(res.data, 'res.data')
        if (res?.data?.data) {
          dispatch(requestPending(false))
          dispatch(setCokeFileUploadSuccess(true))
          // dispatch(setCokeBankStatementFileList(res.data.data))
          dispatch(setFileListDynamic({data: res.data.data, var: stateVar}))
          // dispatch(
          //   uploadFileSuccess({
          //     data: {
          //       data: {
          //         fileUploadPending: false,
          //       },
          //     },
          //     selectedId,
          //   })
          // )
          return
        } else {
          console.log('res?.data?.message', res?.data?.message)
          openNotification('error', res?.data?.message)
          dispatch(requestPending(false))
        }
      })
      .catch((ex) => {
        dispatch(requestPending(false))
        openNotification('error', 'Failed To upload File')
        let error = JSON.parse(JSON.stringify(ex))
        if (error.status === 400) {
          dispatch(requestPending(false))
          // dispatch(
          //   uploadFileSuccess({
          //     data: {
          //       data: {
          //         isError: true,
          //         message: error.message,
          //         fileUploadPending: false,
          //       },
          //     },
          //     selectedId,
          //   })
          // )
        }
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
      })
  }

//upload customer invoice data
export const uploadCustomerInvoiceFile =
  (formData, selectedId, controller, stateVar, openNotification) => (dispatch) => {
    dispatch(setCokeFileUploadSuccess(false))
    dispatch(requestPending(true))

    dispatch(
      uploadFileSuccess({
        data: {
          data: {
            fileUploadPending: true,
          },
        },
        selectedId,
      })
    )

    API.post('api/FileUploadCustomer/ProcessFilesUploadCustomer', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal: controller?.signal,
    })
      .then((res) => {
        //here we will set data in redux state
        dispatch(requestPending(false))
        dispatch(setCokeFileUploadSuccess(true))
        dispatch(setFileListDynamic({data: res.data.data, var: stateVar}))
        //here will dispatch other function... for invoice detail using group id
        dispatch(getCustomerInvoiceRegister(res.data.data.extraInfo, 1, 10))

        dispatch(setCustomerInvoiceDateId(res.data.data.extraInfo))

        dispatch(
          uploadFileSuccess({
            data: {
              data: {
                fileUploadPending: false,
              },
            },
            selectedId,
          })
        )
      })
      .catch((ex) => {
        console.log(ex)
        dispatch(requestPending(false))
        openNotification('error', 'Failed To upload File')
        let error = JSON.parse(JSON.stringify(ex))
        if (error.status === 400) {
          dispatch(
            uploadFileSuccess({
              data: {
                data: {
                  isError: true,
                  message: error.message,
                  fileUploadPending: false,
                },
              },
              selectedId,
            })
          )
        }
        // dispatch(resetUploadFileResponse(true));
        // dispatch(isError(ex))
      })
  }

export const processFileCustomerInvoice =
  (formData, controller, handleClose, openNotification) => (dispatch) => {
    dispatch(setCokeFileUploadSuccess(false))
    dispatch(requestPending(true))

    API.post('api/FileUploadCustomer/ProcessFilesUploadCustomerInvoice', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal: controller?.signal,
    })
      .then((res) => {
        //here we will set data in redux state
        if (res?.data?.data) {
          dispatch(requestPending(false))
          openNotification('success', 'Successfully uploaded')
          handleClose()
        } else {
          dispatch(requestPending(false))
          openNotification('error', res.data.message)
        }
      })
      .catch((ex) => {
        console.log(ex)
        dispatch(requestPending(false))
        openNotification('error', 'Failed To upload File')
        let error = JSON.parse(JSON.stringify(ex))
      })
  }

export const bankLedgerGet = (formData, controller) => (dispatch) => {
  // dispatch(setCokeFileUploadSuccess(false))
  dispatch(setBankLedgerLoader(true))
  API.post('api/BankLedger/Get', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    signal: controller?.signal,
  })
    .then((res) => {
      //here we will set data in redux state
      dispatch(setBankLedgerLoader(false))
      console.log(res.data.data, 'res.data')
      if (res.data.responseCode == 1000) {
        dispatch(setLedgerData(res.data))
      }
    })
    .catch((ex) => {
      let error = JSON.parse(JSON.stringify(ex))
      dispatch(setBankLedgerLoader(false))
      dispatch(resetLedgerData())
      if (error.status === 400) {
        console.log('error')
      }
    })
}

export const reconcileBankLedgerGet = (formData, controller) => (dispatch) => {
  // dispatch(setCokeFileUploadSuccess(false))
  dispatch(setReconcileBankLedgerLoader(true))
  API.post('api/BankLedger/ReconsileBankLedger', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    signal: controller?.signal,
  })
    .then((res) => {
      //here we will set data in redux state
      dispatch(setReconcileBankLedgerLoader(false))
      console.log(res, 'res.data')
      if (res.data.responseCode == 1000) {
        dispatch(setReconcileLedgerData(res.data.data[0].data))
      }
    })
    .catch((ex) => {
      let error = JSON.parse(JSON.stringify(ex))
      dispatch(setReconcileBankLedgerLoader(false))
      if (error.status === 400) {
        console.log('error')
      }
    })
}

export const getProcessById = (id) => (dispatch) => {
  API.get(`/api/ProcessFile/GetByProcessId?id=${id}`)
    .then((res) => {
      console.log('globalSlice', res.status)
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessFilesList(res.data))
      } else {
        // console.log("GetUpload",res)
      }
    })
    .catch((ex) => {
      // console.log("globalSlice", ex)
      // alert("Something went wrong")
      logError(ex)
      // dispatch(resetUploadFileResponse(true));
      // dispatch(isError(ex))
    })
}

export const resetPFilesList = (payload) => async (dispatch) => {
  dispatch(resetProcessFilesList(payload))
}
export const resetProcessRunsListing = (payload) => async (dispatch) => {
  dispatch(resetProcessRunsList(payload))
}

export const getProcessFileTypeConfigurationCustomerById = (id) => (dispatch) => {
  dispatch(resetCustomerInvoiceDetails())
  API.get(`/api/ProcessFileTypeConfigurationCustomer/GetById?id=${id}`)
    .then((res) => {
      if (res.status === 200) {
        const finalPayload = invoiceFormFieldSet(res.data.data)
        dispatch(setCustomerInvoiceDetails(finalPayload))
      }
    })
    .catch((ex) => {
      dispatch(setCustomerInvoiceDetails([]))
      logError(ex)
      console.log(ex, 'error')
    })
}

export const getCustomerInvoiceRegister = (groupId, currentPage, pageSize) => (dispatch) => {
  dispatch(setCustomerInvoiceLoader(true))
  API.get(
    `/api/CosInvoicesDetails/GetByFileId?page=${currentPage}&pageSize=${pageSize}&id=${groupId}`
  )
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        //here set total records, loader set and data set
        dispatch(setCustomerInvoiceLoader(false))
        dispatch(
          setCustomerInvoiceRegister({data: res.data.data, totalRecords: res.data.totalRecords})
        )
      } else {
        dispatch(setCustomerInvoiceLoader(false))
      }
    })
    .catch((ex) => {
      //loader set Data set
      dispatch(setCustomerInvoiceLoader(false))
      logError(ex)
    })
}

export const getAllCustomers = (payloadFormData) => (dispatch) => {
  dispatch(requestPending(true))
  API.post(`/api/Customer/GetAll`, payloadFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => {
      console.log('res', res)
      dispatch(requestPending(false))
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        //here set total records, loader set and data set
        dispatch(requestPending(false))
        //dispatch customers set
        dispatch(setCustomers(res.data.data.data))
        dispatch(setTotalCustomers(res.data.data.totalRecords))
      } else {
        dispatch(requestPending(false))
      }
    })
    .catch((ex) => {
      //loader set Data set
      dispatch(requestPending(false))
      logError(ex)
    })
}

const initialState = {
  isError: false,
  isPending: false,
  isResponsePending: false,
  isDetailResponsePending: false,
  fileUploadFailed: false,
  fileUploadSuccess: null,
  processFileIds: [],
  processFilesLists: [],
  processRunsList: [],
  processRunsData: [],
  pageIndex: 1,
  pageSize: 10,
  currentTotalAmount: 0,
  currentTotalRecords: 0,
  currentListProcessCount: 0,
  cokeFileUploadSuccess: false,
  cokeBankStatementProcessList: [],
  bankLedgerLoader: false,
  bankLedgerData: [],
  totalBankLedgerData: 0,
  reconcileBankLedgerLoader: false,
  reconcileBankLedgerData: [],
  totalReconcileBankLedgerData: 0,
  bankStatement: [],
  invoiceRegister: [],
  customerInvoiceDetail: [],
  bankLedgerSaib: [],
  invoiceCustomerDetails: [],
  customerInvoiceRegister: [],
  customerInvoiceRegisterLoader: false,
  totalCustomerInvoiceRegister: 0,
  customerInvoiceDateId: '',
  customers: [],
  totalCustomers: null,
}

const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    ErrorAlert: (state, {payload}) => {
      state.isError = true
    },
    requestPending: (state, {payload}) => {
      state.isPending = payload
    },
    uploadFileSuccess: (state, {payload}) => {
      // state.fileUploadPending = false;
      state.fileUploadSuccess = true
      state.fileData = payload.data
      state.selectedId = payload.selectedId
      state.processFileIds = [
        ...state.processFileIds.filter((fl) => fl.selected_id !== payload.selectedId),
        {
          process: payload.data.data,
          selected_id: payload.selectedId,
        },
      ]
      // state.isPending = false
    },
    cancelFileUploading: (state, {payload}) => {
      state.fileUploadSuccess = false
      state.fileData = {}
      state.selectedId = ''
      state.processFileIds = [
        ...state.processFileIds.filter((fl) => fl.selected_id !== payload.selectedId),
      ]
      state.isPending = false
    },
    removeFile: (state, {payload}) => {
      // state.fileUploadPending = false;

      state.processFileIds = [...state.processFileIds.filter((fl) => fl.selected_id !== payload)]
    },
    setProcessFilesList: (state, {payload}) => {
      state.processFilesLists = payload.data
      state.currentListProcessCount = payload.data.filter((mp) => mp.isMandatory).length
    },
    resetProcessFilesList: (state, {payload}) => {
      state.processFilesLists = payload
      state.processFileIds = payload
    },
    resetProcessRunsList: (state, {payload}) => {
      state.processRunsList = []
    },
    resetRunData: (state, {payload}) => {
      state.processRunsData = []
    },
    setProcessRunStart: (state, {payload}) => {
      state.isPending = false
      state.startProcessSuccess = true
    },
    resetProcessRunStart: (state, {payload}) => {
      state.startProcessSuccess = false
    },
    setProcessRunLoader: (state, {payload}) => {
      state.getProcessRunDataByTypeLoader = payload
    },
    setCokeFileUploadSuccess: (state, {payload}) => {
      console.log('payload', payload)
      state.cokeFileUploadSuccess = payload
    },
    setCokeBankStatementFileList: (state, {payload}) => {
      console.log('payload', payload)
      state.cokeBankStatementProcessList = [...state.cokeBankStatementProcessList, payload]
    },
    setFileListDynamic: (state, {payload}) => {
      // console.log('payload', payload)
      state[payload.var] = state[payload.var] || []
      state[payload.var].push(payload.data)
    },
    setBankLedgerLoader: (state, {payload}) => {
      state.bankLedgerLoader = payload
    },
    setReconcileBankLedgerLoader: (state, {payload}) => {
      state.reconcileBankLedgerLoader = payload
    },
    setLedgerData: (state, {payload}) => {
      state.bankLedgerData = payload.data
      state.totalBankLedgerData = payload.totalRecords
    },
    setReconcileLedgerData: (state, {payload}) => {
      state.reconcileBankLedgerData = payload
    },
    resetLedgerData: (state) => {
      state.bankLedgerData = []
      state.totalBankLedgerData = null
    },
    resetReconcileLedgerData: (state) => {
      state.reconcileBankLedgerData = []
    },
    setCustomerInvoiceDetails: (state, {payload}) => {
      state.customerInvoiceDetail = payload
    },
    resetCustomerInvoiceDetails: (state) => {
      state.customerInvoiceDetail = []
    },
    setCustomerInvoiceLoader: (state, {payload}) => {
      state.customerInvoiceRegisterLoader = payload
    },
    resetCustomerInvoiceRegister: (state) => {
      state.customerInvoiceRegister = []
      state.totalCustomerInvoiceRegister = 0
    },
    setCustomerInvoiceRegister: (state, {payload}) => {
      state.customerInvoiceRegister = payload.data
      state.totalCustomerInvoiceRegister = payload.totalRecords
    },
    setCustomerInvoiceDateId: (state, {payload}) => {
      state.customerInvoiceDateId = payload
    },
    setCustomers: (state, {payload}) => {
      state.customers = payload
    },
    resetCustomers: (state) => {
      state.customers = []
    },
    setTotalCustomers: (state, {payload}) => {
      state.totalCustomers = payload
    },
    resetTotalCustomers: (state) => {
      state.totalCustomers = null
    },
  },
})

export const {
  ErrorAlert,
  requestPending,
  uploadFileSuccess,
  cancelFileUploading,
  removeFile,
  resetProcessFilesList,
  resetProcessRunsList,
  resetRunData,
  setCokeFileUploadSuccess,
  setCokeBankStatementFileList,
  setBankLedgerLoader,
  setReconcileBankLedgerLoader,
  setLedgerData,
  setReconcileLedgerData,
  resetLedgerData,
  resetReconcileLedgerData,
  setProcessFilesList,
  setFileListDynamic,
  setCustomerInvoiceDetails,
  resetCustomerInvoiceDetails,
  setCustomerInvoiceLoader,
  resetCustomerInvoiceRegister,
  setCustomerInvoiceRegister,
  setCustomerInvoiceDateId,
  setCustomers,
  resetCustomers,
  setTotalCustomers,
  resetTotalCustomers,
} = globalSlice.actions

export default globalSlice.reducer
