import {useState, useEffect} from 'react'
import {Layout, Avatar, theme, Menu, Breadcrumb, Dropdown} from 'antd'
// import pic from './avatar/300-1.jpg'
import {
  ConsoleSqlOutlined,
  LogoutOutlined,
  RadiusSettingOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import {useAuth} from '../../app/modules/auth'
import {UserOutlined} from '@ant-design/icons'
import {GetCurrentUser, convertToTitleCase, logoutFunc} from '../../utils/DefaultFunctions'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {resetlogin} from '../../app/Store/authSlice'
import {STRINGS} from '../../utils/Enums'
const {Header} = Layout

const TopBar = () => {
  const dispatch = useDispatch()
  const {currentUser, logout} = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const [menuVisible, setMenuVisible] = useState(false)
  const user = GetCurrentUser()
  console.log(user, 'userss')

  const handleAvatarClick = () => {
    setMenuVisible(!menuVisible)
  }

  useEffect(() => {
    const pathSnippets = location.pathname.split('/').filter((i) => i)
    const breadcrumbItems = pathSnippets.map((snippet, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      return {
        path: url,
        breadcrumbName: convertToTitleCase(snippet),
      }
    })

    setBreadcrumbs(breadcrumbItems)
  }, [location.pathname])

  const {
    token: {colorBgContainer},
  } = theme.useToken()

  const items = [
    {
      key: '1',
      label: <Link to={`${STRINGS.ROUTES.CHANGE_PASSWORD}`}>Change Password</Link>,
      icon: <RadiusSettingOutlined />,
    },
    // {
    //   key: '2',
    //   label: <Link to={`${STRINGS.ROUTES.USER_PROFILE_UPDATE}`}>Settings</Link>,
    //   icon: <SettingOutlined />,
    // },
    {
      key: '2',
      label: (
        <a
          onClick={() => {
            logout()
            dispatch(resetlogin())
            // logoutFunc()
            sessionStorage.removeItem('user')
          }}
        >
          Logout
        </a>
      ),
      icon: <UserOutlined />,
    },
  ]

  return (
    <>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 0 0 1em',
          position: 'sticky',
        }}
      >
        <div className='mt-5 card-title fw-bold' style={{margin: '0px 0px 0px 1em'}}>
          <Breadcrumb
            className='fs-5 fs-sm-2'
            items={breadcrumbs.map((item) => {
              return {
                title: item.breadcrumbName,
              }
            })}
          />
        </div>

        <div
          className='d-flex gap-2 align-content-center'
          style={{position: 'absolute', top: -16, right: 0, padding: '16px'}}
        >
          <span className='fw-bold'>{`Welcome, ${user?.firstName} ${user?.lastName}`}</span>
          <Dropdown
            menu={{
              items,
            }}
            placement='topRight'
            arrow
          >
            <a onClick={(e) => e.preventDefault()}>
              <Avatar
                size='large'
                icon={<UserOutlined />}
                // onClick={handleAvatarClick}
                style={{
                  backgroundColor: '#fde3cf',
                  color: '#f56a00',
                  cursor: 'pointer',
                }}
              />
            </a>
          </Dropdown>
          {/* */}
        </div>
      </Header>
      {/* <Menu
        style={{
          position: 'absolute',
          top: '60px',
          right: '20px',
          display: menuVisible ? 'block' : 'none',
          width: '113px',
        }}
      >
        <Menu.Item key='2' icon={<LogoutOutlined />} onClick={logOut}>
          Logout
        </Menu.Item>
      </Menu> */}
    </>
  )
}

export default TopBar
