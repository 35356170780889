import {useState, useRef, FC, ChangeEvent, useEffect, useCallback, useMemo} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'

import {
  uploadFileRequest,
  cancelFileUploading,
  removeFile,
  uploadCustomerInvoiceFile,
  requestPending,
} from '../../../../app/GlobalSlices/global.slice'
import {useDispatch} from 'react-redux'
import {AppDispatch, useAppSelector} from '../../../../utils/hook'
import {formatAMPM, getCurrentDate, objectToFormData} from '../../../../utils/DefaultFunctions'
import {Select, notification, Button, Switch, Input} from 'antd'
import {API} from '../../../../utils/services'
import {AxiosError, AxiosResponse} from 'axios'
import {debounce} from 'lodash'

const {Option} = Select

type Props = {
  show: boolean
  handleClose: () => void
  ID: String
  stateVar: String
}

type NotificationType = 'success' | 'info' | 'warning' | 'error'

type Slices = {globalSlice: any}

const modalsRoot = document.getElementById('root-modals') || document.body

const StartNewProcessModal: FC<Props> = ({show, handleClose, ID, stateVar}) => {
  const dispatch = useDispatch<AppDispatch>()
  const [fileId, setFileId] = useState<string>('')
  const [HeaderIncluded, setHeaderIncluded] = useState<Number>()
  const [listArray, setListArray] = useState<any>([])
  const [selectedFile, setSelectedFile] = useState<any>()
  const [controller, setController] = useState<any>(null)
  const [fileNameState, setFileName] = useState<Record<string | number, string>>({})

  const [payload, setPayload] = useState<any>({
    take: '100',
    skip: '0',
    page: '1',
    pageSize: '300',
    'sort[0][field]': 'id',
    'sort[0][dir]': '',
    'filter[logic]': 'or',
    'filter[filters][0][value]': '1',
    'filter[filters][0][field]': 'OutletNumber',
    'filter[filters][0][operator]': 'contains',
    'filter[filters][0][ignoreCase]': 'true',
    'filter[filters][1][value]': '1',
    'filter[filters][1][field]': 'OutletName',
    'filter[filters][1][operator]': 'contains',
  })
  const [customerEnable, setCustomerEnable] = useState<boolean>(true)
  const [groupId, setGroupId] = useState<string>('')
  const [customers, setCustomers] = useState<any>([])
  const [selectedCustomer, setSelectedCustomer] = useState<String>('')

  const {globalSlice} = useAppSelector(({Reducers}) => Reducers as Slices)

  const getAllCustomers = async () => {
    const payloadFormData = objectToFormData(payload)

    try {
      const response: AxiosResponse = await API.post('/api/Customer/GetDD', payloadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.status === 200) {
        console.log(response.data.data, 'respooooo')
        setCustomers(response.data.data)
      }
    } catch (error) {
      const err = error as AxiosError
      console.log('err', err)
    }
  }

  useEffect(() => {
    if (ID === '427F26D6-D2F9-42A4-A3FF-F5751D37372E') {
      // Your code here
      getAllCustomers()
    }
  }, [payload])

  useEffect(() => {
    if (selectedFile) {
      uploadFile()
    }
  }, [selectedFile])

  useEffect(() => {
    if (globalSlice.processFilesLists.length !== 0) {
      let updatedArrayList: any = []
      updatedArrayList = globalSlice.processFilesLists
        .filter((fl: any) => fl !== undefined)
        .map((mp: any) => {
          return {
            ...mp,
            headerIncluded: 1,
          }
          // })
        })

      setListArray(updatedArrayList)
    }
  }, [globalSlice.processFilesLists])

  const [api, contextHolder] = notification.useNotification()

  const openNotification = (type: NotificationType, message: any) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  useEffect(() => {
    if (globalSlice.cokeFileUploadSuccess) {
      closeModel()
    }
  }, [globalSlice.cokeFileUploadSuccess])

  const handleOnChange = function (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    fileName: string,
    headerIncluded: Number,
    index: any
  ) {
    const fileList = e.target.files

    console.log(index, 'index', fileList)
    // if (!fileList?.length || Object.keys(fileList).length < 1) {
    //   // Your code here
    //   return
    // }
    if (!fileList) return
    setSelectedFile(fileList[index])
    setFileId(id)
    setHeaderIncluded(headerIncluded)
    // setFileName({fileList[0].name})
    console.log(fileList, 'fileList')
    setFileName((prev: any) => {
      return {
        ...prev,
        [index]: String(fileList[0].name),
      }
    })
  }

  const uploadFile = function () {
    if (selectedFile) {
      // const controller = new AbortController()
      // const signal = controller.signal
      const formData = new FormData()

      formData.append(`File`, selectedFile)

      formData.append('HeaderIncluded', `${HeaderIncluded}`)
      formData.append('ProcessFileId', `${fileId}`)
      const newController = new AbortController()
      setController(newController)
      if (ID !== '427F26D6-D2F9-42A4-A3FF-F5751D37372E') {
        dispatch(uploadFileRequest(formData, fileId, newController, stateVar, openNotification))
      }
    }
  }

  const abortFunc = (id: any) => {
    //In this function we will set state to the initial one
    if (controller) {
      setFileId('')
      setSelectedFile(null)
      setFileName({})
      // Reset the value of the file input element
      const fileInput = document.getElementById(id) as HTMLInputElement
      if (fileInput) {
        fileInput.value = ''
      }
      controller.abort()
      setController(null)
      const payload = {selectedId: fileId}
      dispatch(cancelFileUploading(payload))
    }
  }

  const onSearchval = useCallback(
    debounce((val: string) => {
      if (val) {
        setPayload((prev: any) => {
          return {
            ...prev,
            'filter[filters][0][value]': val,
            'filter[filters][1][value]': val,
          }
        })
      } else {
        setPayload((prev: any) => {
          return {
            ...prev,
            'filter[filters][0][value]': '1',
            'filter[filters][1][value]': '1',
          }
        })
      }
    }, 300),
    []
  )

  const onSearch = (value: string) => {
    console.log(value, 'valuessss')
    onSearchval(value)
  }

  const onChange = (value: string) => {
    console.log(`selected ${value}`)
    setSelectedCustomer(value)
  }

  const fileUploadCustomer = () => {
    //check both file and selected customer
    if (customerEnable) {
      if (!selectedCustomer) {
        openNotification('error', 'please select customer')
        return
      }
    } else {
      if (!groupId) {
        openNotification('error', 'GroupId is required')
        return
      }
    }

    if (selectedFile) {
      // const controller = new AbortController()
      // const signal = controller.signal
      const formData = new FormData()

      formData.append(`File`, selectedFile)
      if (customerEnable) {
        formData.append('CustomerId', `${selectedCustomer}`)
      } else {
        formData.append('GroupId', `${groupId}`)
      }

      formData.append('HeaderIncluded', `${HeaderIncluded}`)
      formData.append('ProcessFileId', `${fileId}`)

      const newController = new AbortController()
      setController(newController)

      dispatch(
        uploadCustomerInvoiceFile(formData, fileId, newController, stateVar, openNotification)
      )
    }
  }

  const filterOption = (input: any, option: any) => {
    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
  }

  const closeModel = () => {
    //handle close model with state reset or will other resets here
    handleClose()
    setFileName({})
    setFileId('')
    setSelectedFile(null)
    dispatch(requestPending(false))
  }

  const onChangeCheckbox = (val: any) => {
    setCustomerEnable(val)
  }

  const onChangeGroupId = (e: any) => {
    if (!Number(e.target.value)) {
      return
    }
    setGroupId(e.target.value)
  }
  const validKeyForPayment = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace']

  console.log(listArray, 'listArray')

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={closeModel}
      style={{zIndex: 1000}}
    >
      {contextHolder}
      <div className='modal-header pb-0 border-0 justify-content-end'>
        {/*begin::Close*/}
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          data-bs-dismiss='modal'
          onClick={closeModel}
        >
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
          <span className='svg-icon svg-icon-1'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                opacity='0.5'
                x='6'
                y='17.3137'
                width='16'
                height='2'
                rx='1'
                transform='rotate(-45 6 17.3137)'
                fill='currentColor'
              />
              <rect
                x='7.41422'
                y='6'
                width='16'
                height='2'
                rx='1'
                transform='rotate(45 7.41422 6)'
                fill='currentColor'
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
        </div>
        {/*end::Close*/}
      </div>

      <div className='modal-body scroll-y px-10 px-lg-15 pt-0 pb-15'>
        {/*begin:Form*/}
        <div id='kt_modal_new_target_form' className='form'>
          {/*begin::Heading*/}
          <div className='mb-100 text-center'>
            {/*begin::Title*/}
            <h1 className='mb-3'>Start New Process</h1>
            {/*end::Title*/}
            {/*begin::Description*/}
            <div className='text-muted fw-semibold fs-5'>
              Fill all the fields to start a new process
              <a className='fw-bold link-primary' />.
            </div>
            {/*end::Description*/}
          </div>
          {/*end::Heading*/}
          {/*begin::Input group*/}
          {ID === '427F26D6-D2F9-42A4-A3FF-F5751D37372E' && (
            <>
              <div style={{marginBottom: '1em'}}>
                <Switch
                  checkedChildren='Enable Customers'
                  unCheckedChildren='Enable GroupId'
                  defaultChecked={customerEnable}
                  onChange={onChangeCheckbox}
                />
              </div>

              <div className='d-flex gap-4 fv-row' style={{position: 'relative', zIndex: 1001}}>
                {/*begin::Label*/}

                {/* <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>Select Customer</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify a target name for future usage and reference'
                  />
                </label> */}
                {/*end::Label*/}
                <Select
                  showSearch
                  placeholder='Select Customer'
                  optionFilterProp='children'
                  filterOption={filterOption}
                  onChange={onChange}
                  onSearch={onSearch}
                  allowClear
                  style={{width: '50%', height: '43px'}}
                  disabled={!customerEnable ? true : false}
                >
                  {customers &&
                    customers.map((el: any) => (
                      <Option
                        key={el.id}
                        value={el.id}
                      >{`${el.outletName} - ${el.outletNumber}`}</Option>
                    ))}
                </Select>
                <Input
                  placeholder='GroupId'
                  style={{
                    width: '50%',
                    height: '35px',
                  }}
                  disabled={customerEnable ? true : false}
                  onChange={onChangeGroupId}
                  onKeyDown={(e) => {
                    if (!validKeyForPayment.includes(e.key)) {
                      e.preventDefault()
                    }
                  }}
                />
                {/* <input
                  // className='form-control '

                  placeholder={`GroupId`}
                  name='groupId'
                  style={{
                    width: '50%',
                    height: '35px',
                    background: 'white',
                    borderRadius: '5px',
                    border: '1px solid #bbb6b6',
                  }}
                  type='number'
                  disabled={customerEnable ? true : false}
                  onChange={onChangeGroupId}
                /> */}
              </div>
            </>
          )}

          {/*end::Input group*/}
          <br />
          {/*begin::Input group*/}

          {
            <div className='d-flex flex-column  fv-row'>
              {/*begin::Label*/}
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className='required'>File Upload</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='All files are necessery to start a new process.'
                />
              </label>
              {/*end::Label*/}
              <br />
              {/*begin::Form*/}
              {/*begin::Form*/}
              <div>
                {listArray.length !== 0 &&
                  listArray?.map(
                    ({id, fileName, isMandatory, headerIncluded}: any, index: number) => {
                      return (
                        <div
                          key={id}
                          className='form-group '
                          style={{padding: '5px', display: 'flex', alignItems: 'center'}}
                        >
                          {/*begin::Col*/}

                          <div>
                            <label style={{margin: '0 5px'}}>Headers Included one</label>
                            <label>
                              <input
                                type='radio'
                                className='btn-check'
                                name={`${id}`}
                                onClick={() => {
                                  // setHeaderIncluded(1)
                                  let upd: any = []
                                  upd = listArray.map((mp: any) => {
                                    return {
                                      ...mp,
                                      headerIncluded: mp.id === id ? 1 : mp.headerIncluded,
                                    }
                                  })
                                  setListArray(upd)
                                }}
                                value='1'
                                defaultChecked={headerIncluded === 1}
                              />
                              <span
                                style={{padding: '5px', margin: '0 2px'}}
                                className='btn btn-sm btn-color-muted btn-active btn-active-primary'
                              >
                                Yes
                              </span>
                            </label>

                            <label>
                              <input
                                onClick={() => {
                                  // setHeaderIncluded(0)
                                  let upd: any = []
                                  upd = listArray.map((mp: any) => {
                                    return {
                                      ...mp,
                                      headerIncluded: mp.id === id ? 0 : mp.headerIncluded,
                                    }
                                  })
                                  setListArray(upd)
                                }}
                                type='radio'
                                className='btn-check'
                                name={`${id}`}
                                value='0'
                                defaultChecked={headerIncluded === 0}
                              />
                              <span
                                style={{padding: '5px', margin: '0 2px'}}
                                className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
                              >
                                No
                              </span>
                            </label>
                          </div>

                          <input
                            id={id}
                            className='form-control form-control-solid'
                            type='file'
                            onChange={(e) => handleOnChange(e, id, fileName, headerIncluded, index)}
                            hidden
                            // multiple
                            // disabled={selectedFile ? true : false}
                          />

                          <label
                            htmlFor={id}
                            className='btn btn-sm '
                            id='custom-button'
                            style={{
                              backgroundColor: '#18a689',
                              color: 'white',
                              padding: '5px',
                              margin: '0 5px',
                            }}
                          >
                            {fileName}
                          </label>
                          {ID === '427F26D6-D2F9-42A4-A3FF-F5751D37372E' && fileNameState && (
                            <p>{fileNameState[String(index)]}</p>
                          )}
                          {globalSlice.isPending && (
                            <>
                              <span id='custom-text' className='spinner-border spinner-border-sm' />
                              {/**here we will abort uploading api */}

                              <i
                                onClick={() => abortFunc(id)}
                                className='fa-solid fa-xmark ms-5'
                                style={{
                                  color: 'red',
                                  cursor: 'pointer',
                                  fontSize: '20px',
                                }}
                              ></i>
                            </>
                          )}
                          {!fileNameState && <span id='custom-text'>No file chosen, yet.</span>}
                          {isMandatory && <span className='required' />}
                          {/*end::Col*/}
                        </div>
                      )
                    }
                  )}
              </div>
              {/*end::Form*/}
              {/*end::Form*/}
            </div>
          }

          {/*end::Input group*/}
          <br />
          {/*begin::Input group*/}
          <div className='row g-9'></div>
          {/*end::Input group*/}
          <br />
          {/*begin::Actions*/}
          {
            <div className='d-flex justify-content-center gap-4'>
              <Button onClick={closeModel}>Cancel</Button>
              {ID === '427F26D6-D2F9-42A4-A3FF-F5751D37372E' && (
                <Button onClick={fileUploadCustomer} type='primary' loading={globalSlice.isPending}>
                  Upload
                </Button>
              )}
            </div>
          }
          {/*end::Actions*/}
        </div>
        {/*end:Form*/}
      </div>
    </Modal>,
    modalsRoot
  )
}
export {StartNewProcessModal}
