import {useEffect, useState} from 'react'
import {Layout, Menu, theme, Avatar} from 'antd'
import {useLocation} from 'react-router-dom'
import AsideMenuMain from './AsideMenuMain'
import logo from '../../../../media/cokeLogo.png'
import collapsedLogo from '../../../../media/cokeSide.png'
import './sider.css'

const {Sider} = Layout
const {SubMenu} = Menu
const SideBar = () => {
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState([])

  useEffect(() => {
    // Split the pathnames into an array and remove empty items
    const pathnames = location.pathname.split('/').filter(Boolean)
    // Select the first pathname as the selected key
    setSelectedKeys([pathnames[0]])
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 2040) {
        setCollapsed(true)
      } else {
        setCollapsed(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div className=''>
        {!collapsed && (
          <>
            <img
              src={logo}
              alt='Logo'
              style={{width: '100%', height: '7em', display: 'flex', justifyContent: 'center'}}
            />
          </>
        )}
        {collapsed && <img src={collapsedLogo} alt='Logo' style={{width: '100%', height: '4em'}} />}
      </div>
      <div style={{overflowY: 'auto', height: 'calc(100vh - 130px)'}}>
        <AsideMenuMain />
      </div>
    </Sider>
  )
}

export default SideBar
