import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {AuthInit} from './modules/auth'
import storeToolkit from './Store/storeToolkit'
import {Provider} from 'react-redux'

const App = () => {
  // console.log = console.warn = console.error = () => {}

  // // Look ma, no error!
  // console.error('Something bad happened.')
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Provider store={storeToolkit}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              {/* <MasterInit/> */}
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Provider>
    </Suspense>
  )
}

export {App}
