/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {useDispatch} from 'react-redux'
import {AppDispatch, useAppSelector} from '../../../../utils/hook'
import {getLogin} from '../../../Store/authSlice'
import {isPending} from '@reduxjs/toolkit'
import {API} from '../../../../utils/services'

const initialValues = {
  email: '',
  password: '',
}

type Slices = {authSlice: any; globalSlice: any}

console.log(process.env.REACT_APP_AD_ENABLED, 'process.env.AD_ENABLED')

export function Login() {
  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState<any>({isPending: null, isSuccess: null})
  const [isBranch, setIsBranch] = useState(false)
  const [isAuth, setIsAuth] = useState<any>({})
  const {saveAuth, setCurrentUser} = useAuth()

  const {authSlice, globalSlice} = useAppSelector(({Reducers}) => Reducers as Slices)

  const [expirationTime, setExpirationTime] = useState(1000)

  useEffect(() => {
    console.log('authSlice////////////////////', authSlice)

    if (!authSlice.pending && authSlice.loginSuccess) {
      setLoading(true)
      saveAuth(authSlice)
      setCurrentUser(authSlice)
      console.log('hello')
    }
  }, [authSlice.pending, authSlice.loginSuccess])

  const dispatch = useDispatch<AppDispatch>()
  const formik = useFormik({
    initialValues,
    // validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const {email, password} = values
      if (values.email !== '' && values.password !== '') {
        dispatch(getLogin({email, password}))
      } else {
        setStatus('Please enter your credentials to proceed!')
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Enter Your Credentials to login
          {/*<Link to='/auth/registration' className='link-primary fw-bolder'>*/}
          {/*  Create an Account*/}
          {/*</Link>*/}
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div />
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={'form-control'}
          type='email'
          name='email'
          autoComplete='on'
          style={{
            backgroundColor: 'g',
          }}
        />
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          </div>
        </div>
        <input
          type='password'
          autoComplete='on'
          {...formik.getFieldProps('password')}
          className={'form-control'}
          placeholder='Password'
        />
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!authSlice.pending && <span className='indicator-label'>Sign-in</span>}
          {authSlice.pending && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
        {authSlice.error && <div className='text-gray-400  fs-4'>{authSlice.error}</div>}
      </div>
    </form>
  )
}
