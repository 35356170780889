import {combineReducers} from '@reduxjs/toolkit'
import globalSlice from '../GlobalSlices/global.slice'
import authSlice from './authSlice'
import userSlice from './user/userSlice'

const Reducers = combineReducers({
  globalSlice: globalSlice,
  authSlice: authSlice,
  userSlice: userSlice,
})

export default Reducers
