import {createSlice} from '@reduxjs/toolkit'
import {getUsers, addUser, updateUser} from './userActions'

const UserSlice = createSlice({
  name: 'UserSlice',
  initialState: {
    users: [],
    loading: false,
    error: null,
    total: 0,
    page: 1,
    pageSize: 10,
    addLoader: false,
    isModalOpen: false,
    user: {},
  },
  reducers: {
    resetUsers: (state) => {
      state.customers = []
    },
    showModal: (state) => {
      console.log('calll')
      state.isModalOpen = true
    },
    closeModal: (state) => {
      state.isModalOpen = false
    },
    setUserDetail: (state, {payload}) => {
      console.log(payload)
      state.customer = payload
    },
    resetUserDetail: (state) => {
      state.customer = {}
    },
    updatePageNumber: (state, {payload}) => {
      state.page = payload
    },
    updatePageSize: (state, {payload}) => {
      state.pageSize = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(addUser.pending, (state) => {
        state.addLoader = true
      })
      .addCase(updateUser.pending, (state) => {
        state.addLoader = true
      })
      .addCase(getUsers.fulfilled, (state, {payload}) => {
        console.log(payload, 'payload')
        state.users = payload.data
        state.total = payload.totalRecords
        state.loading = false
      })
      .addCase(addUser.fulfilled, (state, {payload}) => {
        state.addLoader = false
      })
      .addCase(updateUser.fulfilled, (state, {payload}) => {
        state.addLoader = false
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false
        state.error = 'Error'
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addLoader = false
        state.error = 'Error'
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.addLoader = false
        state.error = 'Error'
      })
  },
})
export const {
  resetUsers,
  showModal,
  closeModal,
  setUserDetail,
  resetUserDetail,
  updatePageNumber,
  updatePageSize,
} = UserSlice.actions

export default UserSlice.reducer
