import {createAsyncThunk} from '@reduxjs/toolkit'
import {API} from '../../../utils/services'
import {toast} from 'react-toastify'
import {closeModal} from './userSlice'
import {objectToFormData} from '../../../utils/DefaultFunctions'

export const getUsers = createAsyncThunk('users/getUsers', async (payload, {rejectWithValue}) => {
  try {
    console.log(payload, 'pppppp')
    const response = await API.post(`/api/Account/GetAllUser`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response?.data?.data
  } catch (error) {
    console.log(error, 'error')
    return rejectWithValue(error.response.data)
  }
})

export const addUser = createAsyncThunk(
  'users/addUser',
  async ({payload, page, pageSize, openNotificationWithIcon}, {rejectWithValue, dispatch}) => {
    try {
      const response = await API.post(`/api/Account/SignUp`, payload)
      console.log(response, 'response')
      if (response.status === 200) {
        openNotificationWithIcon('success', 'Success', response.data.message)
      }
      dispatch(closeModal())
      payload = {
        take: pageSize,
        skip: 0,
        page: page,
        pageSize: pageSize,
      }
      const payloadFormData = objectToFormData(payload)
      dispatch(getUsers(payloadFormData))
    } catch (error) {
      console.log(error, 'error')
      openNotificationWithIcon('error', 'Error', 'Failed')
      dispatch(closeModal())
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({payload, page, pageSize, openNotificationWithIcon}, {rejectWithValue, dispatch}) => {
    try {
      console.log(payload, 'payssss')

      const response = await API.put(`/api/Account/updateUser`, payload)
      console.log(response, 'response')
      if (response.status === 200) {
        openNotificationWithIcon('success', 'Success', response.data.message)
      }
      dispatch(closeModal())
      payload = {
        take: pageSize,
        skip: 0,
        page: page,
        pageSize: pageSize,
      }
      const payloadFormData = objectToFormData(payload)
      dispatch(getUsers(payloadFormData))

      return rejectWithValue('Not added')
    } catch (error) {
      console.log(error, 'error')
      openNotificationWithIcon('error', 'Error', 'Failed')
      dispatch(closeModal())
      return rejectWithValue(error.response.data)
    }
  }
)

export const getUserById = async (id) => {
  try {
    const response = await API.get(`/Customer/getById?CustomerId=${id}`).then((res) => res)
    console.log(response)
    return response.data.result
  } catch (error) {
    console.log(error, 'error')
    return
  }
}
