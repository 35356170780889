import {configureStore} from '@reduxjs/toolkit'
import createReducer from './rootReducers'

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducers', () => {
    const newRootReducer = require('./rootReducers').default
    storeToolkit.replaceReducer(newRootReducer.createReducer())
  })
}

const middlewares = []

//Uncomment if you want to see logs of redux
// if (process.env.NODE_ENV === 'development') {
//   const {logger} = require('redux-logger')
//   middlewares.push(logger)
// }

const storeToolkit = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      //     {
      //     ignoredActions: [
      //         'dialog/openDialog',
      //         'dialog/closeDialog',
      //         'message/showMessage',
      //         'message/hideMessage',
      //     ],
      // },
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
})

storeToolkit.asyncReducers = {}

export const injectReducers = (key, reducer) => {
  if (storeToolkit.asyncReducers[key]) {
    return false
  }
  storeToolkit.asyncReducers[key] = reducer
  storeToolkit.replaceReducer(createReducer(storeToolkit.asyncReducers))
  return storeToolkit
}

export default storeToolkit
