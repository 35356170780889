import {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {Footer} from './components/Footer'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {ThemeModeProvider} from '../partials'
import SideBar from './components/aside/SideBar'
import {Layout, theme} from 'antd'
import './masterLayout.css'
import TopBar from './TopBar'
// import {convertToTitleCase} from '../../utils/DefaultFunctions'

const MasterLayout = () => {
  // const location = useLocation()
  // const [breadcrumbs, setBreadcrumbs] = useState<{path: string; breadcrumbName: string}[]>([])

  // useEffect(() => {
  //   const pathSnippets = location.pathname.split('/').filter((i) => i)
  //   const breadcrumbItems = pathSnippets.map((snippet, index) => {
  //     const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
  //     return {
  //       path: url,
  //       breadcrumbName: convertToTitleCase(snippet),
  //     }
  //   })

  //   setBreadcrumbs(breadcrumbItems)
  // }, [location.pathname])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <SideBar />
          <Layout className='site-layout' style={{overflow: 'scroll'}}>
            <TopBar />
            {/* <div className='mt-5  card-title fw-bold' style={{margin: ' 0px 0px 0px 1em'}}>
              <Breadcrumb style={{fontSize: '22px'}}>
                {breadcrumbs.map((item) => (
                  <Breadcrumb.Item key={item.path}>{item.breadcrumbName}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div> */}
            <Content>
              <Outlet />
            </Content>

            <Footer />
          </Layout>
        </Layout>
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
