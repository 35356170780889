const DOMAIN_PREFIX = ''

export const STRINGS = {
  BANK_STATEMENT_ID: '60647640-D074-403F-A96D-2A3063F5DF3D',
  CUSTOMER_INVOICE_DETAIL_ID: '427F26D6-D2F9-42A4-A3FF-F5751D37372E',
  BANK_LEDGER_SAIB_ID: '0B72A644-A2B7-4C88-BD5F-7DB915654741',
  INVOICE_REGISTER_ID: 'F2C9AB21-24FD-409B-81B7-B06F0FFBD2F5',

  API_STATUS: {
    DEFAULT: 200,
    CREATE: 201,
    BAD_REQUEST: 400,
    FAIL: 1001,
    SUCCESS: 1000,
  },
  ROUTES: {
    ROOT: `${DOMAIN_PREFIX}/`,
    DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
    BANK_RECIEPT: `${DOMAIN_PREFIX}/bank_receipt`,
    EDIT_BANK_RECIEPT: `${DOMAIN_PREFIX}/bank_reciept/edit`,
    BANK_LEDGER_STATEMENT: `${DOMAIN_PREFIX}/bank_ledger`,
    RECONCILE_BANK_LEDGER: `${DOMAIN_PREFIX}/reconcile_bank_ledger`,
    FORCE_MATCH_BANK_LEDGER: `${DOMAIN_PREFIX}/force_match_bank_ledger`,
    FORCE_MATCH_INVOICE_REGISTER: `${DOMAIN_PREFIX}/invoice_register_matching`,
    UPLOAD_FILES: {
      BANK_STATEMENT: `${DOMAIN_PREFIX}/upload_files/bank_statement`,
      INVOICE_REGISTER: `${DOMAIN_PREFIX}/upload_files/invoice_register`,
      // CUS_INVOICE_DETAILS: `${DOMAIN_PREFIX}/upload_files/customer_invoice_details`,
      BANK_LEDGER_SAIB: `${DOMAIN_PREFIX}/upload_files/bank_ledger_saib`,
    },
    CUSTOMER: {
      ADD_CUSTOMER: `${DOMAIN_PREFIX}/customer/customers`,
      UPDATE_CUSTOMER: `${DOMAIN_PREFIX}/customer/customers/update_customer`,
      MAP_CUSTOMER_INVOICE: `${DOMAIN_PREFIX}/customer/map_invoice_customer`,
      CUSTOMER_INVOICE: `${DOMAIN_PREFIX}/customer/customer_invoice`,
    },
    // BANK_RECIEPT_SUBMIT: `${DOMAIN_PREFIX}/bank_reciept/submit`,
    USERS: `${DOMAIN_PREFIX}/users`,
    CHANGE_PASSWORD: `${DOMAIN_PREFIX}/change_password`,
    // USER_PROFILE_UPDATE: `${DOMAIN_PREFIX}/user_update`,
    USER_ROLES: `${DOMAIN_PREFIX}/user_roles`,
  },
}
