import {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import {useLayout} from '../core'
import {WithChildren} from '../../helpers'

const Content: FC<WithChildren> = ({children}) => {
  return (
    <div id='kt_content_container' style={{width: '100%', padding: '0 1rem', marginTop: '2em'}}>
      {/*className={clsx(classes.contentContainer.join(' '))}*/}
      {children}
    </div>
  )
}

export {Content}
