import {useEffect, useState} from 'react'
import {
  PieChartOutlined,
  ReconciliationOutlined,
  UploadOutlined,
  DashboardOutlined,
  CaretRightOutlined,
  UserOutlined,
  UserAddOutlined,
  FileTextOutlined,
  OneToOneOutlined,
  UsergroupAddOutlined,
  CrownOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import {Menu} from 'antd'
import {STRINGS} from '../../../../utils/Enums'
import {Link, useLocation} from 'react-router-dom'

const {SubMenu} = Menu

const AsideMenuMain = () => {
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState([])

  useEffect(() => {
    // Split the pathnames into an array and remove empty items
    const pathnames = location.pathname.split('/').filter(Boolean)
    // Select the first pathname as the selected key
    setSelectedKeys([pathnames[0]])
  }, [location])

  return (
    <Menu theme='dark' selectedKeys={selectedKeys} mode='vertical'>
      <Menu.Item key='dashboard' icon={<DashboardOutlined />}>
        <Link to={`${STRINGS.ROUTES.DASHBOARD}`}>{'Dashboard'}</Link>
      </Menu.Item>

      <SubMenu key='upload_files' icon={<UploadOutlined />} title={'Upload Files'}>
        <Menu.Item key='company-location' icon={<CaretRightOutlined />}>
          <Link to={`${STRINGS.ROUTES.UPLOAD_FILES.BANK_STATEMENT}`}>
            {'Bank Statement AlRajhi'}
          </Link>
        </Menu.Item>
        <Menu.Item key='company-location' icon={<CaretRightOutlined />}>
          <Link to={`${STRINGS.ROUTES.UPLOAD_FILES.BANK_LEDGER_SAIB}`}>
            {'Bank Statement Saib'}
          </Link>
        </Menu.Item>
        <Menu.Item key='company-location' icon={<CaretRightOutlined />}>
          <Link to={`${STRINGS.ROUTES.UPLOAD_FILES.INVOICE_REGISTER}`}>{'Invoice Register'}</Link>
        </Menu.Item>
        {/* <Menu.Item key='company-location' icon={<CaretRightOutlined />}>
          <Link to={`${STRINGS.ROUTES.UPLOAD_FILES.CUS_INVOICE_DETAILS}`}>
            {'Customer Invoice Details'}
          </Link>
        </Menu.Item> */}

        {/* <Menu.Item key='company-location' icon={<CaretRightOutlined />}>
          <Link to={`${STRINGS.ROUTES.UPLOAD_FILES.BANK_RECIEPT}`}>{'Bank Reciept'}</Link>
        </Menu.Item> */}
      </SubMenu>

      {/* <Menu.Item key='Invoice-Report' icon={<AppstoreOutlined />}>
          <Link to={`${STRINGS.ROUTES.INVOICE_REPORT}`}>{'Invoice Report'}</Link>
        </Menu.Item>
        <Menu.Item key='Payment-Advice' icon={<AppstoreOutlined />}>
          <Link to={`${STRINGS.ROUTES.PAYMENT_ADVICE}`}>{'Payment Advice'}</Link>
        </Menu.Item>
        <Menu.Item key='reports' icon={<AppstoreOutlined />}>
          <Link to={`${STRINGS.ROUTES.REPORTS}`}>{'Reports'}</Link>
        </Menu.Item> */}

      <Menu.Item key='bank_ledger' icon={<PieChartOutlined />}>
        <Link to={`${STRINGS.ROUTES.BANK_LEDGER_STATEMENT}`}>{'View Bank Statement'}</Link>
      </Menu.Item>
      <Menu.Item key='reconcile_bank_ledger' icon={<ReconciliationOutlined />}>
        <Link to={`${STRINGS.ROUTES.RECONCILE_BANK_LEDGER}`}>{'Reconcile Bank Ledger'}</Link>
      </Menu.Item>

      <Menu.Item key='force_match_bank_ledger' icon={<OneToOneOutlined />}>
        <Link to={`${STRINGS.ROUTES.FORCE_MATCH_BANK_LEDGER}`}>{'ForceMatch Bank Ledger'}</Link>
      </Menu.Item>

      <SubMenu key='customer' icon={<UserOutlined />} title={'Customer'}>
        <Menu.Item key='add_customer' icon={<UserAddOutlined />}>
          <Link to={`${STRINGS.ROUTES.CUSTOMER.ADD_CUSTOMER}`}>{'Customers'}</Link>
        </Menu.Item>
        <Menu.Item key='map_customer_invoice' icon={<UserAddOutlined />}>
          <Link to={`${STRINGS.ROUTES.CUSTOMER.MAP_CUSTOMER_INVOICE}`}>
            {'Map Customer Invoice'}
          </Link>
        </Menu.Item>
        {/* <Menu.Item key='customer_invoice' icon={<UserAddOutlined />}>
          <Link to={`${STRINGS.ROUTES.CUSTOMER.CUSTOMER_INVOICE}`}>{'Customer Invoice'}</Link>
        </Menu.Item> */}
        <Menu.Item key='force_match_invoice_register' icon={<OneToOneOutlined />}>
          <Link to={`${STRINGS.ROUTES.FORCE_MATCH_INVOICE_REGISTER}`}>
            {'View Matched Invoices'}
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key='bank_reciept' icon={<FileTextOutlined />}>
        <Link to={`${STRINGS.ROUTES.BANK_RECIEPT}`}>{'Bank Receipt'}</Link>
      </Menu.Item>

      <Menu.Item key='users' icon={<UsergroupAddOutlined />}>
        <Link to={`${STRINGS.ROUTES.USERS}`}>{'Users'}</Link>
      </Menu.Item>
      <Menu.Item key='user_roles' icon={<TeamOutlined />}>
        <Link to={`${STRINGS.ROUTES.USER_ROLES}`}>{'User Roles'}</Link>
      </Menu.Item>
    </Menu>
  )
}

export default AsideMenuMain
