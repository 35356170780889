import {lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {STRINGS} from '../../utils/Enums'
// import RecieptSubmit from '../pages/bankReciepts/RecieptSubmit'

const PrivateRoutes = () => {
  const BankStatementWrapper = lazy(() => import('../pages/bankStatement/BankStatementWrapper'))
  const InvoiceRegisterWrapper = lazy(
    () => import('../pages/invoiceRegister/InvoiceRegisterWrapper')
  )
  const BankLedgerSaibWrapper = lazy(() => import('../pages/bankLedgerSaib/BankLedgerSaibWrapper'))
  const CustomerInvoice = lazy(
    () => import('../pages/customer/customerInvoiceUpload/CustomerInvoice')
  )
  const BankLedgerReports = lazy(() => import('../pages/bankLedgerReports/BankLedgerReports'))
  const ReconcileBankLedger = lazy(() => import('../pages/reconcileBankLedger/ReconcileBankLedger'))
  const ForceMatchBankLedger = lazy(() => import('../pages/forceMatchBankLedger/index'))
  const ForceMatchInvoiceRegister = lazy(() => import('../pages/forceMatchInvoiceRegister/index2'))
  const AddCustomer = lazy(() => import('../pages/customer/addCustomer/index'))
  const Dashboard = lazy(() => import('../pages/dashboard/index'))
  const MapCustomerInvoice = lazy(
    () => import('../pages/customer/mapCustomerInvoice/mapCustomerInvoice')
  )
  const UpdateCustomer = lazy(() => import('../pages/customer/addCustomer/customerUpdatePage'))
  const BankReciept = lazy(() => import('../pages/bankReciept/BankReciept'))
  const RecieptAndForm = lazy(() => import('../pages/bankReciept/RecieptAndForm'))
  const EditRecieptAndForm = lazy(() => import('../pages/bankReciept/EditRecieptAndForm'))
  const ChangePassword = lazy(() => import('../pages/changePassword/index'))
  // const ProfileUpdate = lazy(() => import('../pages/users/profileUpdate/index'))
  const Users = lazy(() => import('../pages/users/Users'))
  const UserRoles = lazy(() => import('../pages/userRoles/userRoles'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/**this will be change from /oneLink/IBFT to dashboard when dashboard work will be done */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route
          path='dashboard'
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.UPLOAD_FILES.BANK_STATEMENT}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BankStatementWrapper
                processId={STRINGS.BANK_STATEMENT_ID}
                processTitle='Upload Files'
              />
            </Suspense>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.UPLOAD_FILES.INVOICE_REGISTER}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <InvoiceRegisterWrapper
                processId={STRINGS.INVOICE_REGISTER_ID}
                processTitle='Invoice Register Report'
              />
            </Suspense>
          }
        />
        {/* <Route
          path={`${STRINGS.ROUTES.BANK_RECIEPT}`}
          element={<BankReceiptWrapper processId={''} processTitle='Bank Reciept' />}
        /> */}
        <Route
          path={`${STRINGS.ROUTES.UPLOAD_FILES.BANK_LEDGER_SAIB}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BankLedgerSaibWrapper
                processId={STRINGS.BANK_LEDGER_SAIB_ID}
                processTitle='Bank Ledger Saib'
              />
            </Suspense>
          }
        />
        {/* <Route
          path={`${STRINGS.ROUTES.UPLOAD_FILES.CUS_INVOICE_DETAILS}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <CustomerInvoiceWrapper
                processId={STRINGS.CUSTOMER_INVOICE_DETAIL_ID}
                processTitle='Customer Invoice'
              />
            </Suspense>
          }
        /> */}

        <Route
          path={`${STRINGS.ROUTES.BANK_LEDGER_STATEMENT}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BankLedgerReports processTitle={'Bank Ledger Reports'} />
            </Suspense>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.RECONCILE_BANK_LEDGER}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ReconcileBankLedger processTitle={'Reconcile Bank Ledger'} />
            </Suspense>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.BANK_RECIEPT}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <BankReciept />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.FORCE_MATCH_BANK_LEDGER}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ForceMatchBankLedger />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.FORCE_MATCH_INVOICE_REGISTER}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ForceMatchInvoiceRegister />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.EDIT_BANK_RECIEPT}/:id`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <EditRecieptAndForm />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.BANK_RECIEPT}/:customerId`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <RecieptAndForm />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.CUSTOMER.ADD_CUSTOMER}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AddCustomer />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.CUSTOMER.UPDATE_CUSTOMER}/:id`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <UpdateCustomer />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.CUSTOMER.MAP_CUSTOMER_INVOICE}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <MapCustomerInvoice />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.CHANGE_PASSWORD}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ChangePassword />
            </Suspense>
          }
        />

        {/* <Route
          path={`${STRINGS.ROUTES.USER_PROFILE_UPDATE}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ProfileUpdate />
            </Suspense>
          }
        /> */}

        <Route
          path={`${STRINGS.ROUTES.USERS}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Users />
            </Suspense>
          }
        />

        <Route
          path={`${STRINGS.ROUTES.USER_ROLES}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <UserRoles />
            </Suspense>
          }
        />

        {/* <Route
          path={`${STRINGS.ROUTES.CUSTOMER.CUSTOMER_INVOICE}`}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <CustomerInvoice
                processId={STRINGS.CUSTOMER_INVOICE_DETAIL_ID}
                processTitle='Customer Invoice'
              />
            </Suspense>
          }
        /> */}
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
