/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/auth/cokebg.jpg')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      {/* begin::Content */}
      <div className='d-flex justify-content-around p-10 pb-lg-20 align-items-center'>
        {/* begin::Logo */}
        {/*<a href='#' className='mb-12'>*/}
        {/*    <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.svg')} className='h-45px'/>*/}
        {/*</a>*/}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        {/*begin::Aside*/}
        <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
          {/*begin::Aside*/}
          <div className='d-flex flex-column'>
            {/*begin::Logo*/}
            <a className='mb-7'>
              <img
                alt='Logo'
                src={`${toAbsoluteUrl('/media/auth/cokeLogo.png')}`}
                style={{width: '60em', height: '38em'}}
              />
            </a>
            {/*end::Logo*/}
            {/*begin::Title*/}
            {/* <h2 className='text-white fw-normal m-0'>
              Welcome to <strong>COKE RECON.</strong>
            </h2> */}
            {/*end::Title*/}
          </div>
          {/*begin::Aside*/}
        </div>
        {/*begin::Aside*/}
        <div className='w-lg-500px rounded shadow-sm p-10 p-lg-15 bg-gray-100'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/*<div className='d-flex flex-center flex-column-auto p-10'>*/}
      {/*    <div className='d-flex align-items-center fw-bold fs-6'>*/}
      {/*        <a href='#' className='text-muted text-hover-primary px-2'>*/}
      {/*            About*/}
      {/*        </a>*/}

      {/*        <a href='#' className='text-muted text-hover-primary px-2'>*/}
      {/*            Contact*/}
      {/*        </a>*/}

      {/*        <a href='#' className='text-muted text-hover-primary px-2'>*/}
      {/*            Contact Us*/}
      {/*        </a>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
