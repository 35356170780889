import {Alert, Space} from 'antd'
import {STRINGS} from './Enums'

export function getCurrentDate() {
  const date = new Date()
  // let convertedDate = `${("0"+date.getDate()).slice(-2)}-${("0"+date.getMonth()).slice(-2)}-${date.getFullYear()}`;
  const convertedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(
    -2
  )}-${date.getFullYear()}`
  return convertedDate
}

export function convertISODate(st) {
  const date = new Date(st)
  // let convertedDate = `${("0"+date.getDate()).slice(-2)}-${("0"+date.getMonth()).slice(-2)}-${date.getFullYear()}`;
  const convertedDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(
    -2
  )}-${date.getFullYear()}`
  return convertedDate
}

export function formatAMPM(st) {
  let date = new Date(st)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  let strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export function formatDateDDMM(dateString) {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1 // Months are 0-indexed, so we add 1
  const formattedDate = `${day}/${month < 10 ? '0' : ''}${month}`
  return formattedDate
}

export function formatDateWithMonthName(dateString) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const date = new Date(dateString)
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear().toString().slice(-2) // Extract the last two digits of the year
  const formattedDate = `${day}-${month}-${year}`
  return formattedDate
}

export function SecondsToTime(st) {
  if (!st) return ''
  let hours = `${st.split('')[0]}${st.split('')[1]}`
  let minutes = `${st.split('')[2]}${st.split('')[3]}`
  let seconds = `${st.split('')[4]}${st.split('')[5]}`
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'

  // var sec_num = parseInt(st, 10); // don't forget the second param
  // var hours = Math.floor(sec_num / 3600);
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  // var minutes = Math.floor((sec_num) / 60);
  // var seconds = sec_num - (minutes * 60);

  // if (hours < 10) { hours = "0" + hours; }
  // if (minutes < 10) { minutes = "0" + minutes; }
  // if (seconds < 10) { seconds = "0" + seconds; }

  return `${hours + ':' + minutes + ':' + seconds} ${hours >= 12 ? 'PM' : 'AM'}`
}

export function convertISOTime(st) {
  const time = st !== undefined ? st.split('T')[1] : '00:00'
  let hours = time.split(':')[0]
  let minutes = time.split(':')[1]
  let ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? minutes : minutes
  let strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export function processTimeFunc(time) {
  let newTime = time.split(':')

  if (parseInt(newTime[0]) === 0) {
    return `${newTime[1]}sec`
  } else {
    return `${newTime[0]}min `
  }
}

export function convertLink(link) {
  if (link === undefined || link === '') return ''

  let path = link
  // let path2 = path.replace(/\\/g, "/");
  // const filePath = path2.split('/Upload/')[1];
  // const filePath = path2.split('/65.21.231.108/')[1];
  // console.log("download_link",  path2);
  // const basePath = window.location.hostname === 'recon.3em.tech' || window.location.hostname === 'localhost' ? `http://recon.3em.tech/upload/` : `http://apiuatrecon.samba.com.pk:8080/upload/`;
  // const basePath = window.location.hostname === 'recon.3em.tech' || window.location.hostname === 'localhost' ? `http://api.recon.3em.tech/` : `http://apiuatrecon.samba.com.pk:8080/`;
  // console.log("download_link",  `${basePath}${filePath}`)
  // return `${basePath}${filePath}`
  return `${path}`
}

export function logError(err) {
  return console.log('Error =>', err)
}

//getCurrentType
export function getCurrentProcessType(type) {
  let value = ''
  if (type === 'un_match') {
    value = STRINGS.MATCH_TYPE.UN_MATCH
  }
  if (type === 'hold_over') {
    value = STRINGS.MATCH_TYPE.HOLD_OVER
  }
  if (type === 'exception') {
    value = STRINGS.MATCH_TYPE.EXCEPTION
  }
  if (type === 'force_match') {
    value = STRINGS.MATCH_TYPE.FORCE_MATCH
  }
  if (type === 'saf') {
    value = STRINGS.MATCH_TYPE.SAF
  }
  return value
}

//getCurrentProcessId
export function getCurrentProcessId(id) {
  let value = ''
  if (id === STRINGS.IBFT_ID) {
    value = 'IBFT'
  }
  if (id === STRINGS.BI_ID) {
    value = 'BI'
  }
  if (id === STRINGS.WD_ID) {
    value = 'ATM_WD'
  }
  if (id === STRINGS.UBP_ID) {
    value = 'UBP'
  }
  if (id === STRINGS.PP_ID) {
    value = 'PP'
  }
  return value
}

export const serialNumberFunc = (pageNo, pageSize) => {
  const startSerial = (pageNo - 1) * pageSize + 1
  return startSerial
}

export const addZerosToString = (inputStr) => {
  // Check the length of the input string
  const length = inputStr.length
  // If the length is less than 6, add leading zeros
  if (length < 6) {
    const zerosToAdd = 6 - length
    const zeros = '0'.repeat(zerosToAdd)
    return zeros + inputStr
  } else {
    // If the length is already 6 or greater, return the input string as is
    return inputStr
  }
}

export const objectToFormData = (obj) => {
  const formData = new FormData()

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key])
    }
  }

  return formData
}

export const convertToTitleCase = (str) => {
  // Convert camelCase to space-separated
  const camelToSpaces = str.replace(/([a-z])([A-Z])/g, '$1 $2')

  // Replace underscores with spaces
  const withSpaces = camelToSpaces.replace(/_/g, ' ')

  // Capitalize the first letter of each word
  const titleCase = withSpaces.replace(/\b\w/g, (match) => match.toUpperCase())
  return titleCase
}

export const excelColumns = [
  {value: 'Col_A', label: 'A'},
  {value: 'Col_B', label: 'B'},
  {value: 'Col_C', label: 'C'},
  {value: 'Col_D', label: 'D'},
  {value: 'Col_E', label: 'E'},
  {value: 'Col_F', label: 'F'},
  {value: 'Col_G', label: 'G'},
  {value: 'Col_H', label: 'H'},
  {value: 'Col_I', label: 'I'},
  {value: 'Col_J', label: 'J'},
  {value: 'Col_K', label: 'K'},
  {value: 'Col_L', label: 'L'},
  {value: 'Col_M', label: 'M'},
  {value: 'Col_N', label: 'N'},
  {value: 'Col_O', label: 'O'},
  {value: 'Col_P', label: 'P'},
  {value: 'Col_Q', label: 'Q'},
  {value: 'Col_R', label: 'R'},
  {value: 'Col_S', label: 'S'},
  {value: 'Col_T', label: 'T'},
  {value: 'Col_U', label: 'U'},
  {value: 'Col_V', label: 'V'},
  {value: 'Col_W', label: 'W'},
  {value: 'Col_X', label: 'X'},
  {value: 'Col_Y', label: 'Y'},
  {value: 'Col_Z', label: 'Z'},
  {value: 'Col_AA', label: 'AA'},
  {value: 'Col_AB', label: 'AB'},
  {value: 'Col_AC', label: 'AC'},
  {value: 'Col_AD', label: 'AD'},
  {value: 'Col_AE', label: 'AE'},
  {value: 'Col_AF', label: 'AF'},
  {value: 'Col_AG', label: 'AG'},
  {value: 'Col_AH', label: 'AH'},
  {value: 'Col_AI', label: 'AI'},
  {value: 'Col_AJ', label: 'AJ'},
  {value: 'Col_AK', label: 'AK'},
  {value: 'Col_AL', label: 'AL'},
  {value: 'Col_AM', label: 'AM'},
  {value: 'Col_AN', label: 'AN'},
  {value: 'Col_AO', label: 'AO'},
  {value: 'Col_AP', label: 'AP'},
  {value: 'Col_AQ', label: 'AQ'},
  {value: 'Col_AR', label: 'AR'},
  {value: 'Col_AS', label: 'AS'},
  {value: 'Col_AT', label: 'AT'},
  {value: 'Col_AU', label: 'AU'},
  {value: 'Col_AV', label: 'AV'},
  {value: 'Col_AW', label: 'AW'},
  {value: 'Col_AX', label: 'AX'},
  {value: 'Col_AY', label: 'AY'},
  {value: 'Col_AZ', label: 'AZ'},
]

export const payloadForInvoice = (id, payload, keyVal) => {
  const newArray = Object.entries(payload).map(([key, value]) => ({
    excelColumn: value.excelColumn,
    tableColumn: key,
    // type: value.type,
    // isAmount: value.isAmount,
    [keyVal]: id,
  }))
  return newArray
}

export const invoiceFormFieldSet = (data) => {
  const newArr = data?.map((el) => {
    return {
      [`${el.tableColumn}_excelColumn`]: el.excelColumn,
      // [`${el.tableColumn}_type`]: el.type,
      // [`${el.tableColumn}_isAmount`]: el.isAmount,
    }
  })
  return newArr
}

export const convertUrlToLocalPath = (url) => {
  // Assuming the base URL is 'http://cokerecon.3em.tech/'
  const baseUrl = 'http://cokerecon.3em.tech/'
  const baseUrlV2 = 'http://localhost:3016/'

  if (url.startsWith(baseUrl)) {
    // Extract the path after the base URL
    const path = url.substring(baseUrl.length)

    // Return the path with a '/' prefix
    return '/' + path
  }

  if (url.startsWith(baseUrlV2)) {
    // Extract the path after the base URL
    const path = url.substring(baseUrlV2.length)

    // Return the path with a '/' prefix
    return '/' + path
  }
}

export const calculateDifference = (data) => {
  let sumCustomer = 0
  let sumCoke = 0

  // console.log(data, 'data')
  if (data) {
    // Iterate through records
    data.records.forEach((record) => {
      // Check rtype and accumulate invoiceAmount accordingly
      if (record.rtype === 'Customer') {
        record.records.forEach((customerRecord) => {
          sumCustomer += parseFloat(customerRecord.invoiceAmount)
        })
      } else if (record.rtype === 'Coke') {
        record.records.forEach((cokeRecord) => {
          sumCoke += parseFloat(cokeRecord.invoiceAmount)
        })
      }
    })

    // Calculate the difference
    const difference = sumCustomer - sumCoke
    return difference.toFixed(2)
  } else {
    return ''
  }
}

export const calculateTotalDifference = (data) => {
  let totalDifference = 0

  if (Object.keys(data).length > 0) {
    // Iterate through records
    for (const property in data) {
      let sumCustomer = 0
      let sumCoke = 0
      data[property].records.forEach((da) => {
        if (da.rtype === 'Customer') {
          da.records.forEach((customerRecord) => {
            sumCustomer += parseFloat(customerRecord.invoiceAmount)
          })
        } else if (da.rtype === 'Coke') {
          da.records.forEach((cokeRecord) => {
            sumCoke += parseFloat(cokeRecord.invoiceAmount)
          })
        }
      })

      // Calculate the difference for the current pair
      const difference = sumCustomer - sumCoke

      // Add the difference to the totalDifference
      totalDifference += difference
    }

    return totalDifference.toFixed(2)
  } else {
    return ''
  }
}

export function GetCurrentUser() {
  const user = sessionStorage.getItem('user')
  return user ? JSON.parse(user) : null
}

export const loginError = (errorMessage) => {
  return (
    <Space
      direction='vertical'
      style={{
        width: '100%',
      }}
    >
      <Alert message={errorMessage} type='error' showIcon />
    </Space>
  )
}
