import {createSlice} from '@reduxjs/toolkit'
import {API} from '../../utils/services'

const initialState = {
  loginSuccess: false,
  invalid: null,
  token: '',
  invalidMessage: '',
  userType: '',
  statusCode: '',
  refreshToken: '',
  pending: false,
  firstName: '',
  lastName: '',
  email: '',
  userID: '',
  error: '',
}

export const getLogin =
  ({email, password}) =>
  (dispatch) => {
    dispatch(setLoader(true))

    API.post('/api/Account/Login', {email: email, password: password})
      .then((res) => {
        console.log('Response', res)
        if (res.data.responseCode === 1000) {
          dispatch(loginDetails(res.data.data))
          dispatch(setLoader(false))
          //here set session storage
          sessionStorage.setItem('user', JSON.stringify(res.data.data))
          sessionStorage.setItem(
            'expiryTime',
            JSON.stringify(new Date().getTime() + 1 * 60 * 60 * 1000) // in 1 hour it will be expired
          )
        }
        if (!res.data.success) {
          dispatch(invalidDetails(res.data))
          dispatch(setLoader(false))
        }
      })
      .catch((err) => {
        console.log(err, 'err')
        dispatch(setLoader(false))
        dispatch(errorMessage('Something Went Wrong!!'))
      })
  }

export const resetlogin = () => (dispatch) => {
  dispatch(resetloginDetails())
}

const authSlice = createSlice({
  name: 'AuthSlice',
  initialState,
  reducers: {
    loginDetails: (state, {payload}) => {
      console.log(payload, 'payload')
      state.loginSuccess = true
      state.token = payload.token
      state.userID = payload.userID
      state.email = payload.email
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      state.isActive = payload.isActive
    },
    resetloginDetails: (state, {payload}) => {
      state.loginSuccess = false
      state.token = ''
    },
    invalidDetails: (state, {payload}) => {
      state.invalid = false
      state.invalidMessage = 'Invalid Email Password'
    },
    setLoader: (state, {payload}) => {
      state.pending = payload
    },
    errorMessage: (state, {payload}) => {
      state.error = payload
    },
  },
})

const {loginDetails, invalidDetails, resetloginDetails, setLoader, errorMessage} = authSlice.actions

export default authSlice.reducer
