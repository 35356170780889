import axios from 'axios'
import {GetCurrentUser} from './DefaultFunctions'

// export const BASE_DOMAIN = (() => {
//   return 'http://api.cokerecon.3em.tech/'
// })()

export const BASE_DOMAIN = (() => {
  console.log(window.location, 'window.location')
  if (
    window.location.hostname === 'cokerecon.3em.tech' ||
    window.location.hostname === 'localhost'
  ) {
    return 'http://api.cokerecon.3em.tech/'
  }
  if (window.location.origin === 'https://recon.coca-cola.com.sa:8336') {
    return 'https://api.recon.coca-cola.com.sa:8337/'
  }
  if (window.location.origin === 'http://recon.coca-cola.com.sa:8336') {
    return 'http://api.recon.coca-cola.com.sa:8337/'
  }
})()

export const BASE_URL = BASE_DOMAIN
export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 3600000,
  httpAgent: true,
})

API.interceptors.request.use((config) => {
  let user = GetCurrentUser()
  if (user && user.token) {
    config.headers.Authorization = `Bearer ${user.token}`
  }
  return config
})

// another interceptor to exclude the Authorization header for certain requests
API.interceptors.request.use((config) => {
  const excludeAuthHeader = config.excludeAuthHeader
  if (excludeAuthHeader) {
    delete config.headers.Authorization
  }
  return config
})
